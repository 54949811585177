<template>
  <div class="flex-grow contaienr mx-auto pt-8 pb-16 px-4">
    <div class="max-w-[60rem] mx-auto">
      <h1 class="text-xl md:text-2xl font-semibold mb-6">Terms of use</h1>

      <div class="space-y-4">
        <h2 class="font-bold">*** SPECIAL LEGAL NOTICE ***</h2>

        <div>
          <b>ALL PROPERTY</b> inclusive of
          <b
            >SOURCE MATERIAL i.e. DOCUMENTS / BOOKS / ARTICLES / PDF’s /GRAHICS
            / AUDIO / VIDEO / DESIGNS and CONCEPTUAL CREATION; Contained within
            FILE Folder(s) or resident on any device(s) belong to the legal
            Owner or his Company.</b
          >
        </div>

        <div class="flex justify-center">
          <div class="max-w-[30rem]">
            <b>ALL PROPERTY</b> purchased, written, created or produced for: –
            <b>Jonathan R. Powell</b> of <b>Arête Publishing Limited</b> U.K.
            the <b>LEGAL OWNER</b> of all the content as described above.
          </div>
        </div>

        <div>
          Copyrights to
          <b>ALL</b> content and format are covered by written Legal License
          Agreements and are covered by both
          <b>U.K. Laws and International Treaty.</b>
        </div>
        <div>
          No Person, Company or Organization may copy, duplicate or obtain
          access to any part or section of the content without the express
          written permission of the legal owner.
        </div>
        <div>
          Failure to comply or disregard the terms set out in this notice will
          in <b>ALL</b> circumstances result in
        </div>
        <div>
          <b>PROSECUTION</b> of the offender(s) and those found to be complicit
          in aiding or assisting in the theft of the property.
        </div>
        <div>
          Full Compensation will be sought for any losses incurred as a result
          of any infringement. Full legal redress will be sought by the Legal
          Owner, his company, partners and all representatives financially
          affected by any infringement to this notice.
        </div>
        <div>
          <b> You Have been Warned!</b>
        </div>
        <div>
          <b>Jonathan R. Powell</b>
        </div>
        <div>
          <b>Aréte Publishing Limited.</b>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup></script>
<style module></style>
